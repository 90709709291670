@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600&display=swap');


@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  src: url('./assets/fonts/PoppinsLatins600.woff2') format('woff2');
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  src: url('./assets/fonts/PoppinsLatins700.woff2') format('woff2');
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 800;
  src: url('./assets/fonts/PoppinsLatins800.woff2') format('woff2');
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 900;
  src: url('./assets/fonts/PoppinsLatins900.woff2') format('woff2');
}

@font-face {
  font-family: 'icomoon';
  src: url('./assets/fonts/icomoon.eot?4zkasz');
  src: url('./assets/fonts/icomoon.eot?4zkasz#iefix') format('embedded-opentype'),
  url('./assets/fonts/icomoon.ttf?4zkasz') format('truetype'),
  url('./assets/fonts/icomoon.woff?4zkasz') format('woff'),
  url('./assets/fonts/icomoon.svg?4zkasz#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

/*FORM*/
.group-form {
  margin-bottom: 10px;
}


.success-case > ul {
  display: flex !important;
  justify-content: center;
}

.success-case > ul > li {
  margin-right: 5px;
}

.success-case > ul > .slick-active > div > div {
  background: white !important;
}

.success-case .slick-slide {
  margin-top: 0 !important;
  padding-top: 0 p !important;
}

.depoimentos > ul {
  display: flex !important;
  justify-content: center;
}

.depoimentos > ul > li {
  margin-right: 5px;
}

.depoimentos > ul > .slick-active > div > div {
  background: #01bfb3 !important;
}

.depoimentos .slick-slide {
  margin-top: 0 !important;
  padding-top: 0 p !important;
}

#whatsapp-link {
	padding: 10px;
	background: #25D366;
	position: fixed;
	bottom: 20px;
	right: 20px;
	border-radius: 70px;
	height: 70px;
}
#whatsapp-link img {
	scale: 1.3;
}

.group-form label {
  font-size: 1.0rem;
  color: #323232;
  font-family: 'bold', Arial, Helvetica, sans-serif;
}

.group-form-copy {
  color: #323232;
  font-family: Poppins;
}

.group-form-copy input {
  margin-top: 5px;
}

.group-form-copy ul {
  list-style: none;
  margin-top: 5px;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.group-form-copy li {
  width: 31%;
  border: 1px solid #d8e3ef;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 43px;
  padding: 10px 10px 14px 10px;
  border-radius: 4px;
}

.group-form-copy li label {
  color: #323232;
  font-family: 'regular', Arial, Helvetica, sans-serif;
  cursor: pointer;
  margin-top: 3px;
}


.group-form-copy input {
  margin-top: 5px;
}

.group-form ul {
  list-style: none;
  margin-top: 5px;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.group-form li {
  width: 31%;
  border: 1px solid #d8e3ef;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 43px;
  padding: 10px 10px 14px 10px;
  border-radius: 4px;
}

.group-form li label {
  color: #323232;
  font-family: 'regular', Arial, Helvetica, sans-serif;
  cursor: pointer;
  margin-top: 3px;
  font-size: 1.0rem;
}

input {
  background: #FFF;
  border: 1px solid #d8e3ef;
  padding: 12px;
  font-family: 'medium', 'Helvetica', Arial, sans-serif;
  font-size: 1.2rem;
  width: 100%;
  border-radius: 3px;
}

.valid {
  border: 1px solid #3ccf7a !important;
}

.error {
  border: 1px solid #ee1d2c !important;
}

select {
  background: #FFF;
  border: 1px solid #d8e3ef;
  padding: 12px;
  font-family: 'medium', 'Helvetica', Arial, sans-serif;
  font-size: 1.2rem;
  /*height: 40px;*/
  width: 100%;
  border-radius: 3px;
  margin-top: 5px;
}

/* CheckBox Styles */

.option-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  position: relative;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 17px;
  width: 17px;
  transition: all 0.15s ease-out 0s;
  background: #FFF;
  border: none;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  margin-right: 5px;
  outline: none;
  position: relative;
  z-index: 10;
  border: 1px solid #d9e4ef;

  border-radius: 100%;
}




.option-input:hover {
  background: #FFF;
  border: 1px solid #d9e4ef;
}

.option-input:checked {
  background: #dc0e28;
}

.option-input:checked::before {
  height: 17px;
  width: 17px;
  content: '';
  position: absolute;
  background: url(/assets/images/check.svg);
  fill: #FFF;
  display: inline-block;
  font-size: 2.6rem;
  text-align: center;
  line-height: 40px;
  top: 4px;
  right: 3px;
  background-size: 17px;
  background-repeat: no-repeat;
  filter: invert(100%);
}

.option-input:checked::after {
  background: #dc0e28;
  content: '';
  display: block;
  position: relative;
  z-index: 100;
}

.option-input.radio {
  border-radius: 50%;
}

.option-input.radio::after{
  border-radius: 50%;
}


.two-col {
  width: 48%;
}

.box-input {
  background: #FFF;
  border: 1px solid #d8e3ef;
  padding: 12px 12px 14px 12px;
  width: 100%;
  border-radius: 3px;
  height: 45px;
  margin-top: 5px;

  display: flex;
  justify-content: flex-start;
  align-items: center;
}

body .box-input label {
  font-family: 'regular', Arial, Helvetica, sans-serif;
  margin-top: 3px;
}

body .box-input label small {
  font-family: 'regular', Arial, Helvetica, sans-serif;
  color: #a9b1bb;
  font-size: 1.1rem;
}

body .box-input label span {
  font-family: 'regular', Arial, Helvetica, sans-serif;
  color: #a9b1bb;
}


.back {
  width: 60px;
  height: 50px;
  background: #d8e3ef;
  color: #495458;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  border: 0;
  transition: background 0.3s;
}

.back:hover {
  background: #c0ccd8;
  color: #495458;
}

.button {
  background: #ee1d2c;
  color: #FFF;
  border-radius: 30px;
  font-size: 1.3rem;
  font-family: 'bold', Arial, Helvetica, sans-serif;

  display: block;
  width: 100%;
  border: 0;
  cursor: pointer;
  padding: 10px;
  transition: 0.2s filter;
}

.button:hover {
  filter: brightness(80%);
}

/* Box Header */
.banner footer {
  //margin: auto;
  //width: 100%;

  //display: grid;
  //grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  //grid-gap: 20px;
}

.banner .box-banner {
  display: flex;
  flex-direction: row;
}

.banner .box-banner:last-child .number {
  padding: 0 4px;
}

.banner .box-banner .number {
  border: 2px solid #ff8991;
  width: 100px;
  height: 60px;
  font-size: 2.0rem;
  font-family: 'bold', Arial, Helvetica, sans-serif;
  color: #FFF;
  margin-right: 20px;
  border-radius: 6px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.banner .box-banner span {
  color: #FFF;
  font-size: 2.0rem;
  font-family: 'bold', Arial, Helvetica, sans-serif;
}

.banner .box-banner p {
  color: #fff;
  font-size: 1.2rem;
  font-family: 'regular', Arial, Helvetica, sans-serif;
  margin-top: 15px;
  opacity: 0.9;
}


/* Icons */
[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-icone-furto-roubo:before {
  content: "\e900";
}

.icon-icone-colisoes:before {
  content: "\e901";
}

.icon-icone-perda-total:before {
  content: "\e902";
}

.icon-icone-incendio:before {
  content: "\e903";
}

.icon-icone-granizo:before {
  content: "\e904";
}

.icon-icone-brasil:before {
  content: "\e905";
}

.icon-check-2:before {
  content: "\e906";
}

.icon-check:before {
  content: "\e907";
}

.icon-left:before {
  content: "\e908";
}

.icon-whatsapp:before {
  content: "\e909";
}

.icon-icone-carro-reserva:before {
  content: "\e90a";
}

.icon-icone-vidros:before {
  content: "\e90b";
}

#list-protection i {
  width: 30px;
  height: 30px;
  font-size: 2.0rem;
  color: #c02036;
}

.lists {
}

.lists li {
  display: flex;
  flex: 1;
  border-bottom: 1px solid #d8e3ef;
  padding: 20px 0;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.lists li.first-li .product span {
  padding-left: 45px;
  font-family: 'bold', Arial, Helvetica, sans-serif;
}

.lists li.first-li .description p {
  font-family: 'bold', Arial, Helvetica, sans-serif;
}

.lists li .product {
  max-width: 335px;
  width: 100%;

  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.lists li .product span {
  font-size: 1.0rem;
  font-family: 'regular', Arial, Helvetica, sans-serif;
  color: #323232;
}

.lists li .product i {
  margin-right: 20px;
  font-size: 1.8rem;
  color: #c02036;
}

.lists li .description {
  flex: 1;
}

.lists li .description p {
  font-size: 1.0rem;
  font-family: 'regular', Arial, Helvetica, sans-serif;
  color: #323232;
}

@media (max-width: 768px) {
  .banner .box-banner .number {
    width: 60px;
    height: 40px;
  }
}

@media (max-width: 600px) {
  .lists li .product {
    max-width: 100%;
  }

  .lists li.first-li {
    display: none;
  }

  .lists li {
    flex-direction: column;
  }

  body .box-input label small {
    font-size: 0.9rem;
  }
}

